import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'
import { PageProps, navigate } from 'gatsby'

// Models | Types
import { SeriesModel } from '../../../models'

// Config
import { series } from '../../../config/localization'

// Templates
import PageTemplate from '../PageTemplate'
import WorldTemplate from '../WorldTemplate'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

// GraphQL
import { getGraphChapters } from '../../../graphQL'

// Utils
import { getFont } from '../../../utils/utils'

// Style
import {
  seriesImg,
  seriesArc,
  seriesArcs,
  seriesArcName,
  seriesArcNumber,
  seriesArcActive,
  seriesLink,
  seriesLeft,
  seriesTitle,
  seriesWrapper,
  seriesChapters,
  seriesDescription,
} from './styles.module.scss'

const SeriesTemplate: React.FC<PageProps<any, SeriesModel>> = ({
  pageContext: { arcs, name, prefix, description, short, id },
}) => {
  const { language } = useContext(LocalizationContext)

  const allChapters = getGraphChapters().filter(
    (ch) => Boolean(ch.published) && ch.series.id === Number(id)
  )

  const [arc, setArc] = useState<number | null>(null)

  const getChapters = () => {
    const chapters = allChapters.filter((ch) =>
      typeof arc === 'number' ? ch.arc.id === arcs[arc - 1].id : true
    )
    return [chapters[0], chapters[chapters.length - 1]]
  }

  return (
    <PageTemplate>
      <Helmet>
        <title>
          {language === 'ESP' ? 'Story' : 'Historia'}
          {`: ${name[language]}`}
        </title>
        <meta
          name="description"
          content={description ? description[language] : ''}
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? `Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, historia, ${name[language]}`
              : `Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, bio, ${name[language]}`
          }
        />
        <meta name="author" content="Daniel Penagos" />
        <meta name="copyright" content="Daniel Penagos" />
      </Helmet>
      <WorldTemplate location="story" title={name[language]}>
        <div className={seriesWrapper}>
          <div className={seriesLeft}>
            <h3 className={`${seriesTitle} ${getFont('font-patua')}`}>
              {typeof arc === 'number'
                ? arcs[arc - 1].name[language]
                : name[language]}
            </h3>
            <div className={`${seriesDescription} ${getFont('font-lato')}`}>
              {arc
                ? arcs[arc - 1].description[language]
                : description[language]}
            </div>
            <div className={`${seriesChapters} ${getFont('font-lato')}`}>
              <div>
                {series[language].first}:
                <span
                  className={seriesLink}
                  onClick={() => {
                    navigate(`/reader/${prefix}${getChapters()[0].number}`)
                  }}
                >{`${String(getChapters()[0].number).padStart(2, '0')} - ${
                  getChapters()[0].title[language]
                }`}</span>
              </div>
              <div>
                {series[language].last}:
                <span
                  className={seriesLink}
                  onClick={() => {
                    navigate(`/reader/${prefix}${getChapters()[1]?.number}`)
                  }}
                >{`${String(getChapters()[1]?.number).padStart(2, '0')} - ${
                  getChapters()[1]?.title[language]
                }`}</span>
              </div>
            </div>
            <div className={seriesArcs}>
              {arcs.map((ar, index) => (
                <div
                  key={`${ar.name}_${ar.id}`}
                  className={`${seriesArc} ${
                    arc === index + 1 ? seriesArcActive : ''
                  }`}
                  onClick={() => {
                    if (arc == index + 1) {
                      setArc(null)
                    } else {
                      setArc(index + 1)
                    }
                  }}
                >
                  <div
                    className={`${seriesArcNumber} ${getFont('font-patua')}`}
                  >
                    {ar.number}
                  </div>
                  <div className={`${seriesArcName} ${getFont('font-lato')}`}>
                    {ar.name[language]}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <img
            className={seriesImg}
            src={
              typeof arc === 'number'
                ? `https://dilancovak.com/.img/story/arc${prefix}${
                    arcs[arc - 1].number
                  }.png`
                : `https://dilancovak.com/.img/story/${short}.png`
            }
          />
        </div>
      </WorldTemplate>
    </PageTemplate>
  )
}

export default SeriesTemplate
