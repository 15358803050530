// extracted by mini-css-extract-plugin
export var seriesArc = "styles-module--series-arc--67pPv";
export var seriesArcs = "styles-module--series-arcs--4Wi2f";
export var seriesArcActive = "styles-module--series-arc-active--NnBRi";
export var seriesArcName = "styles-module--series-arc-name--V-Sz+";
export var seriesArcNumber = "styles-module--series-arc-number--m+LKd";
export var seriesChapters = "styles-module--series-chapters--w20va";
export var seriesDescription = "styles-module--series-description--uuwp2";
export var seriesLeft = "styles-module--series-left---f-sI";
export var seriesImg = "styles-module--series-img--3zKcE";
export var seriesLink = "styles-module--series-link--wnOwM";
export var seriesTitle = "styles-module--series-title--CnpNc";
export var seriesWrapper = "styles-module--series-wrapper--OzvfN";